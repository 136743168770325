<template>
  <section id="footer">
    <aside>
      <h1>Designed and built by Stella Samaranayake</h1>
    </aside>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#footer {
  width: 100%;
  height: 5rem;
  background-color: #451149;
  text-align: center;
}

aside {
  padding-top: 30px;
  font-family: "Karla", sans-serif;
  display: flex;
  justify-content: center;

  h1 {
    font-size: 0.9rem;
    color: #86196d;
  }
}
</style>
